import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import TextTitle from '../../components/TextTitle/TextTitle';
import Post from '../../components/Post/Post';
import PageNavigator from '../../components/PageNavigator/PageNavigator';

import blogService from '../../services/api/BlogService';
import userService from '../../services/api/UserService';

import style from './Posts.module.scss';

const Posts = _ => 
{
    const { id } = useParams();

    const [blogs, setBlogs] = useState([]);
    const [userData, setUserData] = useState(null);

    const [invalidUser, setInvalidUser] = useState(false);
    const [noPosts, setNoPosts] = useState(false);

    const [processed, setProcessed] = useState(false);

    useEffect(_ => {
        const doesUserExist = async _ => {
            try {
                await userService.getUserInfo(id);

                return true;
            } catch (e) {
                return false;
            }
        }

        doesUserExist().then(exist => {
            if (!exist)
                setInvalidUser(true);
            else {
                blogService.getUserBlogCount(id).then(res => {
                    userService.getUserInfo(id).then(res => setUserData(res.data));

                    let count = res.data;

                    if (count === 0)
                        setNoPosts(true);
                    else
                        setProcessed(true);
                })
            }
        });
      }, [id]);

    if (invalidUser) throw new Error("User not found!");

    if (noPosts) throw new Error("User has no posts");

    if (!userData || !processed) return

    return (                
        <div className={style.Main}>
                <TextTitle xstyle={style.Title}>{`Posts of ${userData.username}`}</TextTitle>

                <div className={style.Posts}>
                    {blogs.map(function(object, i) {                        
                        return <Post key={i}
                        post={object}>
                        </Post>;
                    })}
                </div>

                <PageNavigator fetchCount={_ => blogService.getUserBlogCount(id)} fetchItems={async lastId => (await blogService.getUserBlogs(id, lastId)).data} setItems={setBlogs}/>
        </div>        
    );
}

export default Posts;