import { useState } from "react";

import PageNavigator from "../../components/PageNavigator/PageNavigator";

import managementService from '../../services/api/ManagementService';

import style from "./Management.module.scss";

export default _ =>
{
    const [tokenList, setTokenList] = useState([]);

    const generateKey = _ =>
    {
        managementService.create().then(data => {                                    
            setTokenList([...tokenList, data]);
        }).catch(e =>{
            console.error(e);
        })
    }    

    return(
        <div className={style.container}>
            <h1 className={style.title}>Token Manager</h1>

            <div className={style.list}>
                <div className={style.frame}>
                    <div className={style.listitems}>
                    {
                        tokenList.map(function(obj, i)
                        {
                            return (
                                <div className={style.item} key={i}>
                                    <span className={style.token}>{obj.token}</span>
                                    <span className={style.usedBy}>{obj.usedBy != null ? obj.usedBy['username'] : "N/A"}</span>                                    
                                    <span className={style.usedBy}>{obj.id}</span>
                                </div>
                            );
                        })
                    }
                    </div>                    
                </div>       
                <i className="ri-file-add-fill" onClick={generateKey}></i>                
            </div>     

            <PageNavigator fetchCount={managementService.Count} fetchItems={managementService.getPage} setItems={setTokenList}/>
        </div>
    );
}