import { useState } from 'react';

import { useNavigate } from "react-router-dom";

import TextInput from '../../components/TextInput/TextInput';
import TextTitle from '../../components/TextTitle/TextTitle';
import Button from '../../components/Button/Button';
import Label from '../../components/Label/Label';

import userService from '../../services/api/UserService';
import authService from '../../services/AuthService';

import style from './Authenication.module.scss';

const LoginWeb = _ => 
{
    const navigate = useNavigate();

    const [error, setError] = useState("");

    const [success, setSuccess] = useState(false);
    const [formSwitch, switchForm] = useState(true);
    
    const [loginUsername, setLoginUsername] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const [registerUsername, setRegisterUsername] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerToken, setRegisterToken] = useState("");

    const login = async _ => {
        try {
            authService.setToken((await userService.login(loginUsername, loginPassword)).token);

            setSuccess(true);

            setTimeout(_ => navigate("/"), 1000);
        } catch(e) {
            setError(e.response.data.message);

            setTimeout(_ => setError(""), 1000);
        }
    }

    const register = async _ => {
        try {
            await userService.register(registerUsername, registerPassword, registerToken);

            setSuccess(true);

            setTimeout(_ => { setSuccess(false); switchForm(true); }, 1000);
        } catch(e) {
            setError(e.response.data.message);

            setTimeout(_ => setError(""), 1000);
        }
    }
    
    return (
        <>
        <div className={style.container}>
            <div className={`${formSwitch ? style.shown : style.hidden}`}>
            <div className={style.form}>                
                <div className={style.form_options}>
                <TextTitle size="24px" weight="400">Login</TextTitle>
                    <div className={style.form_inputs}>    
                    <TextInput change={e => setLoginUsername(e.target.value)} text="Username" type="text" id="username"></TextInput>
                    {error === "" ? (
                        <TextInput change={e => setLoginPassword(e.target.value)} text="Password" type="password">
                            {success === true ? (
                            <TextTitle size="13px" font="Fira Sans" weight="300" color="green">Successfully Logged In</TextTitle>
                            ) : (<></>)}
                        </TextInput>
                    ) : (          
                        <>        
                        <TextInput change={e => setLoginPassword(e.target.value)} text="Password" type="password" styles={{ border: "1px solid red"}}></TextInput>
                        <TextTitle xstyle={style.text_error}>{error}</TextTitle>          
                        </>                
                    )}
                    <Button style="BlueStyled" trigger={ login }>Login</Button>    
                    
                    <TextTitle size="13px" font="Fira Sans" weight="300">Not a member?<Label color= "#006AB7" font="Fira Sans" weight="600" trigger={_ => switchForm(!formSwitch)}>Sign up</Label></TextTitle>
                    </div>
                </div>
            </div>
            </div>

            <div className={`${formSwitch ? style.hidden : style.shown}`}>
            <div className={style.form}>                
                <div className={style.form_options}>
                <TextTitle size="24px" weight="400">Register</TextTitle>
                    <div className={style.form_inputs}>    
                    <TextInput change={e => setRegisterUsername(e.target.value)} text="Username" type="text"></TextInput>
                    <TextInput change={e => setRegisterPassword(e.target.value)} text="Password" type="password"></TextInput>
                    <br></br>
                    {error === "" ? (
                        <TextInput change={e => setRegisterToken(e.target.value)} text="Token" type="text">
                            {success === true ? (
                            <TextTitle size="13px" font="Fira Sans" weight="300" color="green">Your account have been created!</TextTitle>
                            ) : (<></>)}
                        </TextInput>
                    ) : (                        
                        <TextInput change={e => setRegisterToken(e.target.value)} text="Token" type="text" styles={{ border: "1px solid red"}}>
                            <TextTitle size="13px" font="Fira Sans" weight="300" color="red">{error}</TextTitle>
                        </TextInput>                        
                    )}
                    <Button style="PurpleStyled" trigger={ register }>Register</Button>    
        
                    <TextTitle size="13px" font="Fira Sans" weight="300">Already a member?<Label color= "#6b00b7" font="Fira Sans" weight="600" trigger={_ => switchForm(!formSwitch)}>Sign in</Label></TextTitle>                                
                    </div>
                </div>
            </div>
            </div>
        </div>
    </>);
}

export default LoginWeb;