import React from 'react'

import { Navigate } from 'react-router-dom'

import authService from '../services/AuthService'

const Route = (props) => 
{
    return (        
        authService.isLogged() === !props.public ? <props.component {...props} /> : <Navigate to="/" />
    );
}

export default Route;