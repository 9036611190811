import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import authService from "../services/AuthService"

const AuthVerify = (props) => {
    let location = useLocation();

    useEffect(_ => {
    const user = authService.getData();

    if (user) {
        if (user.exp * 1000 < Date.now()) {
            authService.setToken(null);

            if (props.notifyCallback)
                props.notifyCallback();
        }
    }
    }, [location]);
}

export default AuthVerify;