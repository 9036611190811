import { React, useState, useEffect } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";

import TipTap from "../../components/TipTap/TipTap";

import blogService from '../../services/api/BlogService';
import authService from '../../services/AuthService';

import style from './Post.module.scss';

export default () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [blogDetails, setBlogDetails] = useState([]);
    const [blogData, setBlogData] = useState("");

    const [error, setError] = useState(false);    

    useEffect(_ => {
        Promise.all([
            blogService.getBlogDetails(id),
            blogService.getBlogData(id)
        ]).then(res => {
            setBlogData(res[0].data);
            setBlogDetails(res[1].data);
        }).catch(err => {
            setError(true);
        });
    }, []);

    if (error) throw new Error("Post not found!")

    if (blogDetails == null || blogData == "") return;

    const canEdit = _ => {
        for (var i = 0; i < blogDetails['authors'].length; i++)
            if (authService.getData() && authService.getData().id == blogDetails['authors'][i].id)
                return true;

        return false;
    }

    const deleteBlog = async _ => {
        await blogService.deleteBlog(blogDetails.id).then(_ => {
            navigate(-1)
        }).catch(_ => {
            throw new Response("Not Found", { status: 404 });
        })
    }

    return (
        <>
            <div className={style.main}>
                <div className={style.buttonContainers}>
                    {
                        authService.getData() && authService.getData().id == blogDetails.officialCreator.id &&
                        <>
                            <button className={`${style.buttonIcons} ${style.link}`} onClick={_ => { navigate(`/post/${id}/edit`)}}>
                                EDIT
                            </button>

                            <button className={`${style.buttonIcons} ${style.link}`} onClick={deleteBlog}>
                                CLEAR
                            </button>
                        </>
                    }

                    {
                        canEdit() && authService.getData() && authService.getData().id != blogDetails.officialCreator.id &&
                        <button className={`${style.buttonIcons} ${style.link}`} onClick={_ => {navigate(`/post/${id}/edit`)}}>
                            EDIT
                        </button>
                    }
                </div>
            </div>

            <div className={style.main}>
                <span className={style.date}>{blogDetails.createdDate}</span>
                <h1 className={style.title}>{blogDetails.title}</h1>

                <div className={style.ProfilePicture}>
                    <img className={style.image} src={blogDetails.officialCreator.picture}></img>
                    <Link className={style.username} to={`/profile/${blogDetails.officialCreator.id}`}>{blogDetails.officialCreator != 0 ? blogDetails.officialCreator.username : "N/A"}</Link>
                </div>

                <div className={style.details}>
                    {blogDetails.authors.map((author, i) => {
                        return (<Link key={i} to={`/profile/${author.id}`} className={style.author}>@{author.username}</Link>)
                    })}
                </div>

                <TipTap content={blogData} editable={false} readOnly={true}></TipTap>
            </div>
        </>
    )
}