import axios from '../AxiosService'

const service = {
    getAll: async () => {
        return (await axios.get("Management/Token/GetAll")).data;
    },

    Count: async () => {
        return (await axios.get("Management/Count")).data;
    },

    getPage: async (lastId) => {
        return (await axios.get("Management/Token/GetAll/" + lastId)).data;
    },

    create: async () => {
        return (await axios.get("Management/Token/Create")).data;
    }
}

export default service