import axios from '../AxiosService'

const service = {
    login: async (username, password) => {
        const loginPayload = {
            username: username,
            password: password
        }

        return (await axios.post("User/Login", loginPayload)).data;
    },

    register: async (username, password, token) => {
        const registerPayload = {
            username: username,
            password: password,
            token: token
        }

        return (await axios.post("User/Register", registerPayload)).data;
    },

    getUserInfo: async (userId) => {
        return (await axios.get("User/" + userId)).data;
    },

    updateProfile: async (userImage, username) => {

        const updateProfilePayload = {            
            Picture: userImage,
            Username: username
        }

        return (await axios.post("User/Update", updateProfilePayload)).data;
    },

    getUserInfoFromUsername: async (username) => {
        return (await axios.get("User/Name/" + username)).data;
    },

    getLoggedUser: async _ => {
        return (await axios.get("User")).data;
    }
}

export default service