import axios from './AxiosService'

import decode from "jwt-decode";

let isLogged = _ => !(localStorage.getItem("token") === 'null' || localStorage.getItem("token") === "");

const service = {
    isLogged: isLogged,

    setToken: token => {
        localStorage.setItem("token", token);

        if (token && token !== "")
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        else
            delete axios.defaults.headers.common["Authorization"];
    },

    getData: _ => { if (isLogged()) { return decode(localStorage.getItem("token")) } else { return null; } }
}

export default service