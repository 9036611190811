import { useState } from 'react';

import { useNavigate } from "react-router-dom";

import CodeBlock from './Extensions/CodeBlock/CodeBlock';
import MenuOptions from './MenuOptions';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Highlight from '@tiptap/extension-highlight';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Heading from '@tiptap/extension-heading';
import Code from '@tiptap/extension-code';
import Italic from '@tiptap/extension-italic';
import Bold from '@tiptap/extension-bold';
import Typography from '@tiptap/extension-typography';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Dropcursor from '@tiptap/extension-dropcursor';
import Blockquote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import Strike from '@tiptap/extension-strike';
import Paragraph from '@tiptap/extension-paragraph';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import History from '@tiptap/extension-history';
import TextStyle from '@tiptap/extension-text-style';

import { Emoji } from './Extensions/Emoji/Emoji';
import { Color } from '@tiptap/extension-color';
import { EditorContent, ReactNodeViewRenderer, useEditor } from '@tiptap/react';
import { lowlight } from 'lowlight';

import style from './TipTap.module.scss';

import './Styles.scss';
import 'remixicon/fonts/remixicon.css';

export default (props) => {

  const navigate = useNavigate();

  const [blogData, setBlogData] = useState({ title: "", description: "", html: "", authors: [] });
  const [authorList, setAuthorList] = useState([]);

  const [postError, setPostError] = useState("");

  const uploadBlog = async _ => 
  {
    try {
      await props.postBlog(blogData)

      setTimeout(_ => navigate(-1), 1000)
    } catch(e) {
      if (typeof e === 'string')
        setPostError(e)        
      else
        setPostError(e.response.data.message)

      setTimeout(_ => setPostError(""), 1000)
    }
  }

  const editor = useEditor({
    extensions: [
      Document,  
      Text,
      Paragraph,
      Heading,
      Bold,
      Code,
      Strike,
      Italic,
      OrderedList,
      Blockquote,
      BulletList,
      ListItem,
      Typography,
      Dropcursor,
      Link.configure({autolink: true,validate: href => /^https?:\/\//.test(href),}),
      CodeBlockLowlight.extend({ addNodeView() { return ReactNodeViewRenderer(CodeBlock) } }).configure({ lowlight }),
      Image,
      Emoji,      
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Highlight,
      History,
      TextStyle,
      Color
    ],

    injectCSS: true,
    editable: props['editable'] || false,
    content: props['content'] || "",    
    onUpdate: ({ editor }) => {
      setBlogData(values => ({
        ...values, html: editor.getHTML()
      }));
    },

    onCreate: ({ editor }) => {
      if(!props.details) return;
      props.details['authors'].forEach(function(author, index) {
          setAuthorList(result => [...result, author.username])                   
          blogData.authors.push(author.username)
      })

      setBlogData({
        title: props.details['title'], description: props.details['description'], html: editor.getHTML(), authors: blogData.authors
      })
    }
  });

  const setInfo = e => {    
    setBlogData(values => ({
      ...values, [e.target.name]: e.target.value
    }));
  }

  const setAuthors = e => {
    if(e.target.value == "") {
      setBlogData(values => ({
        ...values, authors: []
      }));

      return;
    }

    setBlogData(values => ({
      ...values, authors: e.target.value.split(", ")
    }));
  }

  return (
    !props["readOnly"] ?
      <>
        <div className={style.MenuEditor}>
          <div className={style.MenuBar}>
            <MenuOptions visibility={props["editable"]} textEditor={editor} />
          </div>

          { props['isUpdate'] ?
            <>
              <input className={style.PostTitle} defaultValue={props.details['title']} onChange={setInfo} placeholder="title" type="text" name="title"></input>
              <input className={style.PostTitle} defaultValue={props.details['description']} onChange={setInfo} placeholder="description" type="text" name="description"></input>
              <input className={style.PostTitle} defaultValue={authorList.toString().split(",").join(", ")} onChange={setAuthors} placeholder="authors" type="text" name="authors"></input>
            </>
            :
            <>
              <input className={style.PostTitle} onChange={setInfo} placeholder="title" type="text" name="title"></input>
              <input className={style.PostTitle} onChange={setInfo} placeholder="description" type="text" name="description"></input>
              <input className={style.PostTitle} onChange={setAuthors} placeholder="authors" type="text" name="authors"></input>
            </>
          }

          <div className={style.Content}>
            <EditorContent editor={editor} autoCorrect="off" spellCheck={false} />
          </div>
        </div>

          <div className={style.PostActions}>
            <button className={style.submit} onClick={uploadBlog}><i className="ri-mail-send-line"></i>{props['isUpdate'] ? "Update" : "Publish"}</button>
          </div>

          { postError !== ""  ?          
            <div className={`${style.Notifier}`}>
            <div className={style.NotifierData}>
              <div className={style.NotifierDataError}>
                <span className={style.ErrorInfo}>{postError}</span>
              </div>
            </div>
          </div>
          :
          <>
          </>
       }
      </>
      :
      <>
        <div className={style.Content}>
          <EditorContent editor={editor} autoCorrect="off" spellCheck={false} />
        </div>
      </>
  )
}