import styles from './TextTitle.module.scss';

const TextTitle = (props) => 
{
    return (        
        <h1 className={`${styles.TextTitle} ${props.xstyle}`} style= {{fontSize: props.size, fontFamily: props.font, fontWeight: props.weight, color: props.color}}>
        {props.children}    
        </h1>
    );
}

export default TextTitle;