import React from 'react';

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import Main from './pages/Main/Main';
import Authenication from './pages/Authenication/Authenication';
import CreatePost from './pages/CreatePost/CreatePost';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Profile from './pages/Profile/Profile';
import Management from './pages/Management/Management';
import Posts from './pages/Posts/Posts';
import Post from './pages/Post/Post';
import Navbar from './components/Navbar/Navbar';
import Edit from './pages/Edit/Edit';

import RouteHandler from './router/Route';

import authService from './services/AuthService';

import './sass/style.scss';
import 'remixicon/fonts/remixicon.css';

authService.setToken(localStorage.getItem("token"));

const AppLayout = () => (
    <>    
    <Navbar/>

    <Outlet/>

    { /* <Footer /> */ }
    </>
);

const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AppLayout/>
      ),

      children: [
      {
        errorElement: <ErrorPage/>,
        
        children: [
          {
            path: "/",
            element: <Main/>
          },
          {
            path: "*",
            loader: _ => {
              throw new Error("Page not found!")
            }
          },
          {
            path: "login",
            element: <RouteHandler component={Authenication} public={true}/>,
          },
          {
            path: "post",
            children: [
              {
                path: "create",
                element: <RouteHandler component={CreatePost} public={false}/>,
              },
              {
                path: ":id",
                element: <Post/>,
              },
              {
                path: ":id/edit",
                element: <Edit/>,
              }
            ]
          },   
          {
            path: "profile",
            children: [
              {
                path: ":id",
                element: <Profile/>
              }
            ]
          },
          {
            path: "management",
            element: <RouteHandler component={Management} public={false}/>          
          },
          {
            path: "user",
            children: [
              {
                path: ":id/posts",
                element: <Posts/>
              }
            ]
          },
        ],
      },
    ],
    }
  ]);

createRoot(document.getElementById("root")).render(    
    <React.StrictMode>
        <RouterProvider router={router} />  
    </React.StrictMode>
);