import { useNavigate, useRouteError } from 'react-router-dom';

import style from './ErrorPage.module.scss';

const ErrorPage = (props) => 
{
    const navigate = useNavigate();
    const err = useRouteError();     

    return (
        <>
        <div className={style.Container}>
            <main className={style.content}>
                <div className={style.Error}>
                    <p>{err.message ? err.message : "Try again later. Something happened at our end"}</p>
                </div>

                <button className={style.cta} onClick={_ => {
                    navigate(-1);
                }}>Go back</button>
            </main>
        </div>
        </>
    )
}

export default ErrorPage;