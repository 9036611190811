import { useEffect, useState } from "react";

import style from "./PageNavigator.module.scss";

const PageNavigator = (props) => 
{
    const [currentPage, setCurrentPage] = useState(0);

    const [tokensCount, setTokenCount] = useState(0);    
    const [pageHistory, setPageHistory] = useState(null);

    const [canNext, setCanNext] = useState(true);
    const [fetching, setFetching] = useState(true);

    useEffect( _ =>{
        props.fetchCount().then(c => {
            setTokenCount(c.data);

            let pages = ((c.data / 12) | 0);

            if (c.data / 12 === ((c.data / 12) | 0)) --pages;

            if (pages == 0)
                setCanNext(false);
        })

        setKeys(0);
    }, [])

    const setKeys = pageNumber =>
    {
        props.fetchItems(pageNumber).then(e =>{
            props.setItems(e);

            setPageHistory([{ lastId: e[e.length - 1].id, previousId: 0 }]);

            setFetching(false);
        })
    }

    const nextPageCallback = page => 
    {                
        props.fetchItems(pageHistory[page].lastId).then(e => {
            let pages = ((tokensCount / 12) | 0);

            if (tokensCount / 12 === ((tokensCount / 12) | 0)) --pages;

            if(page + 1 == pages)
                setCanNext(false);                            
            else            
                setCanNext(true);

            props.setItems(e);                    

            setPageHistory(
                [
                  ...pageHistory,
                  { lastId: e[e.length - 1].id, previousId: pageHistory[page].lastId }
                ]
            );

            setFetching(false);
        });
    }

    const previousPageCallback = page => 
    {                        
        props.fetchItems(pageHistory[page - 1].previousId).then(e => {
            let pages = ((tokensCount / 12) | 0);

            if (tokensCount / 12 === ((tokensCount / 12) | 0)) --pages;

            if(page - 1 == pages)
                setCanNext(false);                            
            else            
                setCanNext(true);            

            props.setItems(e);

            let newArray = [
                ...pageHistory,
                { lastId: e[e.length - 1].id, previousId: pageHistory[page].lastId }
            ];

            newArray.pop();

            setPageHistory(newArray);

            setFetching(false);
        });
    }

    const setPreviousPage = _ => {
        setFetching(true);

        previousPageCallback(currentPage)

        setCurrentPage(currentPage - 1);
    }

    const setNextPage = _ => {
        setFetching(true);
        
        nextPageCallback(currentPage)

        setCurrentPage(currentPage + 1);
    }

    return(
        <div className={style.navigator}>
            <div className={style.frame}>      

                { currentPage > 0 && !fetching ? 
                (
                    <div className={style.pagingbutton} onClick={setPreviousPage}>
                        <i className="ri-rewind-mini-fill"></i>
                    </div>    
                )
                :
                    <div className={`${style.pagingbutton} ${style['greyed-out']}`} onClick={setPreviousPage}>
                        <i className="ri-rewind-mini-fill"></i>
                    </div> 
                }          

                <div className={style.pagingbutton} style={ {backgroundColor: "transparent"} }>
                    <span className={style['page-number']}>{currentPage}</span>
                </div>    

                { canNext && !fetching ? 
                (
                    <div className={style.pagingbutton} onClick={setNextPage}>
                        <i className="ri-speed-mini-fill"></i>
                    </div>   
                )
                :
                    <div className={`${style.pagingbutton} ${style['greyed-out']}`} onClick={setNextPage}>
                        <i className="ri-speed-mini-fill"></i>
                    </div>   
                }

            </div>
        </div>
    );
}

export default PageNavigator;