import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextTitle from '../../components/TextTitle/TextTitle.jsx';
import Post from '../../components/Post/Post.jsx';
import PageNavigator from '../../components/PageNavigator/PageNavigator';

import blogService from '../../services/api/BlogService'
import authService from '../../services/AuthService';

import style from './Main.module.scss';

const Entry = _ => 
{
    const [blogs, setBlogs] = useState([]);

    const navigate = useNavigate();
    
    return (                
        <div className={style.Main}>
                <TextTitle xstyle={style.Title}>Posts</TextTitle>

                <div className={style.Posts}>
                    {blogs.map(function(object, i) {                        
                        return <Post key={i}
                        post={object}>
                        </Post>;
                    })}
                </div>    

                { 
                authService.getData() ? 
                <button className={style.createPost} onClick={_ => { navigate("/post/create") }}>
                    <div className={style.Form}>
                        <i className="ri-quill-pen-line"></i>
                    </div>
                </button> 
                :
                <></>
                }
                
                <PageNavigator fetchCount={blogService.Count} fetchItems={blogService.getPage} setItems={setBlogs}/>
        </div>        
    );
}

export default Entry;