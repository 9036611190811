import { Link } from 'react-router-dom';

import TextTitle from '../TextTitle/TextTitle';
import Dividers from '../Divider/Divider';

import roles from '../../sass/roles.module.scss';
import dividersStyle from '../Divider/Divider.module.scss';

import style from './Post.module.scss';

const Post = (props) => 
{
    return(
        <div className={style.PostContainer}>
        <div className={style.PostFrame}>
            <div className={style.ProfilePictureCircle}>
                <img className={style.ProfilePicture} src={props.post.officialCreator.picture}></img>                    
            </div>
            <TextTitle xstyle={roles.Default}>{props.post.officialCreator['username']}</TextTitle>                        
        </div>

        <Dividers type='horizontal' stylex={dividersStyle.dark}></Dividers>
        
        <div className={style.PostInfo}>
            <TextTitle xstyle={style.PostTitle}>{props.post.title}</TextTitle>                        
            <TextTitle xstyle={style.PostDescription}>{props.post.description}</TextTitle>                        
        </div>

        <div className={style.ContainerInfo}>
            <div className={style.ContainerBox}>                
                <Link className={`ri-book-open-line ${style.PostReadMoreButton}`} to={`/post/${props.post.id}`}></Link>                  
            </div>                    
        </div>
        </div>    
    );
}

export default Post;