import { React, useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import TipTap from "../../components/TipTap/TipTap";

import blogService from '../../services/api/BlogService';
import userService from '../../services/api/UserService';
import authService from '../../services/AuthService';

import style from '../CreatePost/CreatePost.module.scss';

export default () => {
    const { id } = useParams();

    const [blogDetails, setBlogDetails] = useState([]);
    const [blogData, setBlogData] = useState("");    

    const [error, setError] = useState(false);

    useEffect(_ => {
        Promise.all([
            blogService.getBlogDetails(id),
            blogService.getBlogData(id)
        ]).then(res => {
            setBlogData(res[0].data);
            setBlogDetails(res[1].data);
        }).catch(err => {
            setError(true);
        });
    }, [id]);

    if (error) throw new Error("Post not found!")

    if (blogDetails == null || blogData == "") return;    

    const canEdit = _ => {
        if (!authService.getData()) return false;

        if (authService.getData().id == blogDetails['officialCreator'].id) return true

        for (var i = 0; i < blogDetails['authors'].length; i++)
            if (authService.getData() && authService.getData().id == blogDetails['authors'][i].id)                
                return true;

        return false;
    }

    const getAuthorNamesIds = async (authorsNames) => {
        let userIds = []

        try {
            await Promise.all(authorsNames.map(async (username) => {
                let userData = await userService.getUserInfoFromUsername(username);

                userIds.push(userData.data.id);
            }))
        } catch (e) { return null; }

        return userIds
    }

    const updateBlog = async (info) => {    
        
        let authorsIds = [];

        if(info.authors.length > 0) 
        {
            authorsIds = await getAuthorNamesIds(info.authors);

            if (!authorsIds) throw "Invalid Author Username";
        }

        console.log(authorsIds)

        let res = await blogService.updateBlog(blogDetails.id, info.title, info.description, info.html, authorsIds );

        return res;
    }

    if (!canEdit()) throw new Error("Invalid permissions to edit this blog");

    return (
        <>
            { canEdit() &&
                <div className={style.content}>
                    <div className={style.editoroptions}>
                        <TipTap content={blogData} details={blogDetails} editable={true} isUpdate={true} readOnly={false} postBlog={updateBlog}></TipTap>
                    </div>
                </div>
            }
        </>
    )
}