import styles from './TextInput.module.scss';

const TextInput = (props) => 
{
    return (        
        <span className={styles.text}>{props.text}
        <input onChange={props.change} type={props.type} className={styles.inputbox} style={props.styles}>            
        </input>        
        {props.children}
        </span>
    );
}

export default TextInput;