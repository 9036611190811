import { useCallback } from "react";

import style from './TipTap.module.scss';

export default (props) => {
    if (!props.visibility || props.textEditor == null) return;

    const setLink = useCallback(() => {
        const previousUrl = props.textEditor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)
    
        // cancelled
        if (url === null) {
          return
        }
    
        // empty
        if (url === '') {
            props.textEditor.chain().focus().extendMarkRange('link').unsetLink()
            .run()
    
          return
        }
    
        // update link
        props.textEditor.chain().focus().extendMarkRange('link').setLink({ href: url })
          .run()
      }, [props.textEditor])

      const addImage = useCallback(() => {
        const url = window.prompt('URL')
    
        if (url) {
            props.textEditor.chain().focus().setImage({ src: url }).run()
        }
      }, [props.textEditor])
    
      if (!props.textEditor) {
        return null
      }

    const Typography =
    {
        bold: () => {
            props.textEditor.chain().focus().toggleBold().run();
        },

        italic: () => {
            props.textEditor.chain().focus().toggleItalic().run()
        },

        strike: () => {
            props.textEditor.chain().focus().toggleStrike().run()
        },

        codeblock: () => {
            props.textEditor.chain().focus().toggleCodeBlock().run()
        },

        markup: () => {
            props.textEditor.chain().focus().toggleHighlight().run()
        },

        heading: lvl => {
            props.textEditor.chain().focus().toggleHeading({ level: lvl }).run()
        },

        paragraph: () => {
            props.textEditor.chain().focus().setParagraph().run()
        },

        orderedlist: () => {
            props.textEditor.chain().focus().toggleOrderedList().run()
        },

        bulletlist: () => {
            props.textEditor.chain().focus().toggleBulletList().run()
        },

        quotes: () => {
            props.textEditor.chain().focus().toggleBlockquote().run()
        }
    }

    return (
        <div className={style.optionbar}>

            <button onClick={Typography.bold}
                className={`${style.menuitem} ${props.textEditor.isActive('bold') ? 'is-active' : ''}`} title="Bold"><i className="ri-bold"></i>
            </button>

            <button onClick={Typography.strike}
                className={`${style.menuitem} ${props.textEditor.isActive('strike') ? 'is-active' : ''}`} title="Strike"><i className="ri-strikethrough-2"></i>
            </button>

            <button onClick={Typography.italic}
                className={`${style.menuitem} ${props.textEditor.isActive('italic') ? 'is-active' : ''}`} title="Italic"><i className="ri-italic"></i>
            </button>

            <button onClick={Typography.codeblock}
                className={`${style.menuitem} ${props.textEditor.isActive('codeblock') ? 'is-active' : ''}`} title="Codeblock"><i className="ri-code-line"></i>
            </button>

            <button onClick={Typography.markup}
                className={`${style.menuitem} ${props.textEditor.isActive('markup') ? 'is-active' : ''}`} title="Markup"><i className="ri-mark-pen-line"></i>
            </button>

            <div className={style.divider}></div>

            <button onClick={_ => { Typography.heading(1) }}
                className={`${style.menuitem} ${props.textEditor.isActive('heading', { level: 1 }) ? 'is-active' : ''}`} title="Heading 1"><i className="ri-h-1"></i>
            </button>

            <button onClick={_ => { Typography.heading(2) }}
                className={`${style.menuitem} ${props.textEditor.isActive('heading', { level: 2 }) ? 'is-active' : ''}`} title="Heading 2"><i className="ri-h-2"></i>
            </button>

            <button onClick={_ =>{ Typography.heading(3) }}
                className={`${style.menuitem} ${props.textEditor.isActive('heading', { level: 3 }) ? 'is-active' : ''}`} title="Heading 3"><i className="ri-h-3"></i>
            </button>

            <button onClick={Typography.paragraph}
                className={`${style.menuitem} ${props.textEditor.isActive('paragraph') ? 'is-active' : ''}`} title="Paragraph"><i className="ri-paragraph"></i>
            </button>

            <div className={style.divider}></div>

            <button onClick={Typography.orderedlist}
                className={`${style.menuitem} ${props.textEditor.isActive('paragraph') ? 'is-active' : ''}`} title="Ordered List"><i className="ri-list-ordered"></i>
            </button>

            <button onClick={Typography.bulletlist}
                className={`${style.menuitem} ${props.textEditor.isActive('paragraph') ? 'is-active' : ''}`} title="Bullet List"><i className="ri-list-unordered"></i>
            </button>

            <div className={style.divider}></div>
            
            <button onClick={addImage}
                className={`${style.menuitem} ${props.textEditor.isActive('image') ? 'is-active' : ''}`} title="Image"><i className="ri-image-line"></i>
            </button>
            
            <button onClick={setLink}
                className={`${style.menuitem} ${props.textEditor.isActive('link') ? 'is-active' : ''}`} title="Link"><i className="ri-link-m"></i>
            </button>

            <button onClick={Typography.quotes}
                className={`${style.menuitem} ${props.textEditor.isActive('quote') ? 'is-active' : ''}`} title="Quote"><i className="ri-double-quotes-l"></i>
            </button>

            <div className={style.divider}></div>

            <button onClick={ _ => { props.textEditor.chain().focus().setTextAlign("left").run()}}
                className={`${style.menuitem} ${props.textEditor.isActive('align-left') ? 'is-active' : ''}`} title="Align left"><i className="ri-align-left"></i>
            </button>

            <button onClick={ _ => { props.textEditor.chain().focus().setTextAlign("right").run()}}
                className={`${style.menuitem} ${props.textEditor.isActive('align-right') ? 'is-active' : ''}`} title="Align right"><i className="ri-align-right"></i>
            </button>

            <button onClick={ _ => { props.textEditor.chain().focus().setTextAlign("center").run()}}
                className={`${style.menuitem} ${props.textEditor.isActive('align-center') ? 'is-active' : ''}`} title="Align center"><i className="ri-align-center"></i>
            </button>

            <button onClick={ _ => { props.textEditor.chain().focus().setTextAlign("justify").run()}}
                className={`${style.menuitem} ${props.textEditor.isActive('align-justify') ? 'is-active' : ''}`} title="Align justify"><i className="ri-align-justify"></i>
            </button>

            <div className={style.divider}></div>

            <input type="color" className={style["input-colorpicker"]} title="Set Color" onInput={event => props.textEditor.chain().focus().setColor(event.target.value).run()} value={props.textEditor.getAttributes('textStyle').color}/>
        </div>
    )
}