import { useState, useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import blogService from '../../services/api/BlogService';
import userService from '../../services/api/UserService';
import authService from '../../services/AuthService';

import moment from 'moment';

import style from "./Profile.module.scss";

export default _ => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [profileData, setProfileData] = useState(null);
    const [blogsCount, setBlogsCount] = useState(null);

    const [postError, setPostError] = useState("");

    const [hovered, setHovered] = useState(false);
    const [error, setError] = useState(false);

    useEffect(_ => {
        userService.getUserInfo(id).then(res => setProfileData(res.data)).catch(
            err => {
                setError(true);
            });

        blogService.getUserBlogCount(id).then(res => setBlogsCount(res.data));
    }, [id])

    if (error) throw new Error("Profile not found!");

    if (!profileData) return

    const MouseEnter = _ => {
        setHovered(true);
    }

    const MouseLeave = _ => {
        setHovered(false);
    }

    const changeProfile = async _ => {
        try {
            await userService.updateProfile(prompt("URL"), profileData.username);
            setTimeout(_ => navigate(0), 1000)
        }
        catch(e)
        {
            if (typeof e === 'string')
            setPostError(e)        
            else
            setPostError(e.response.data.message)

            setTimeout(_ => setPostError(""), 1000)
        }
    }

    return (
        <div className={style["profile-main-container"]}>
            <div className={style["profile-container-box"]}>
                <div className={style["profile-topbar"]} />

                <div className={style["profile-bottombar"]}>
                    <div className={`${style["profile-image-content"]}`} onMouseEnter={MouseEnter} onMouseLeave={MouseLeave}>
                        <img alt="profile" className={hovered ? style['brig'] : ""} src={profileData['picture']} />

                        {
                            authService.getData() && authService.getData().id == profileData.id &&

                            <div className={style["profile-image-options"]} style={{ display: hovered ? "block" : "none" }}>
                                <button onClick={changeProfile}>EDIT</button>
                            </div>
                        }

                    </div>

                    <h3 className={style["profile-title"]}>profile of <strong>{profileData['username']}</strong></h3>
                    <div className={style["profile-content"]}>

                        <div className={style["profile-content-item"]}>
                            <p>{`${blogsCount} posts`}</p>
                            <Link to={`/user/${profileData['id']}/posts`} className={style["profile-view-posts"]}>view posts</Link>
                        </div>

                        <p className={style["profile-joined-date"]}>{`Joined at ${moment(profileData['registerDate'], "DD/MM/YYYY").format('D MMM, YYYY')}`}</p>
                    </div>
                </div>
            </div>

            {postError !== "" &&
                <div className={`${style.Notifier}`}>
                    <div className={style.NotifierData}>
                        <div className={style.NotifierDataError}>
                            <span className={style.ErrorInfo}>{postError}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}