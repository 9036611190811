import axios from '../AxiosService'

const service = {
    getAllBlogs: async () => {
        return (await axios.get("Blog/GetAll")).data;
    },

    getPage: async (lastId) => {
        return (await axios.get("Blog/GetAll/" + lastId)).data;
    },

    getBlogDetails: async (blogId) => {
        return (await axios.get("Blog/Details/" + blogId)).data;
    },

    getBlogData: async (blogId) => {
        return (await axios.get("Blog/" + blogId)).data;
    },

    getUserBlogCount: async (userId) => {
        return (await axios.get("Blog/BlogsCount/" + userId)).data;
    },

    getUserBlogs: async (userId, lastId) => {
        return (await axios.get("Blog/Blogs/" + userId + "/" + lastId)).data;
    },

    postBlog: async (title, description, content, authors) => {
        const blogPayload = {
            title: title,
            description: description,
            data: content,
            authors: authors
        }

        return (await axios.post("Blog/Create/", blogPayload)).data;
    },

    updateBlog: async (blogId, title, description, content, authors) => {

        const blogPayload = {            
            title: title,
            description: description,
            data: content,
            authors: authors
        }

        return (await axios.put("Blog/Update/", blogPayload, { params: { blogId: blogId }}));
    },

    deleteBlog: async (blogId) => 
    {
        return (await axios.delete("Blog/Delete/", { params: { blogId: blogId } })).data;
    },

    Count: async () => {
        return (await axios.get("Blog/Count")).data;
    }
}

export default service