import React from "react";

import style from './Divider.module.scss';

const Dividers = (props) => 
{
    return (
        <div className={`${props['stylex']} ${props['type'] == 'horizontal' ? style.DividerHorizontal : style.VerticalDivider}`}></div>
    );
}

export default Dividers;