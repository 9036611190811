import TipTap from '../../components/TipTap/TipTap';

import blogService from '../../services/api/BlogService';
import userService from '../../services/api/UserService';

import style from './CreatePost.module.scss';

const CreatePost = _ => {
    const getAuthorNamesIds = async (authorsNames) => {
        let userIds = []

        try {
            await Promise.all(authorsNames.map(async (username) => {
                let userData = await userService.getUserInfoFromUsername(username);

                userIds.push(userData.data.id);
            }))
        } catch (e) { return null; }

        return userIds
    }

    const postBlog = async (info) => 
    {
        let authorsIds = await getAuthorNamesIds(info.authors);

        if (!authorsIds && info.authors.length > 0)
            throw "Invalid Author Username";

        let res = await blogService.postBlog(info.title, info.description, info.html, authorsIds == null ? info.authors : authorsIds);

        return res;
    }
    
    return (
        <div className={style.content}>
            <div className={style.editoroptions}>
                <TipTap editable={true} readOnly={false} isUpdate={false} postBlog={postBlog}></TipTap>
            </div>
        </div>
    );
}

export default CreatePost;